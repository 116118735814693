import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListEnsaios from '@/components/groupProducts/ensaio/ListEnsaios';

export default {
  components: {
    HeaderRegister,
    ListEnsaios,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
